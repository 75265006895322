import React from 'react'

import Button from '../../../Button'
import HomePod from '../../../../images/homepod.png'

export default React.memo(({ onEnterToWinClick, showRaffle }) => {
  return (
    <div className="greeting-message-wrapper">
      <div className="modal-content">
        <h1>Way to go!</h1>
        <p>
          You made all the matches! And you discovered the many ways ADA Members
          Insurance Plans can help meet your unique insurance needs and protect
          your practice, your income and your family.
          {showRaffle &&
            ` Now, don’t forget to enter our raffle for one of six HomePod minis™.`}
        </p>
        {showRaffle && <img src={HomePod} alt="HomePod minis" />}
      </div>
      <div className="modal-footer">
        <Button
          className="primary-btn"
          text="Play Again"
          onPress={() => {
            window.location.reload()
          }}
        />
        <Button
          className="secondary-btn-dark"
          text="Exit Game"
          onPress={() => {
            window.location.href =
              window.location.protocol + '//' + window.location.host
          }}
        />
        {showRaffle && (
          <Button
            className="tertiary-btn"
            text="Enter to win"
            onPress={onEnterToWinClick}
            data-gtm-enter-to-win-button
          />
        )}
      </div>
    </div>
  )
})
