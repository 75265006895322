import React from 'react'

import Button from '../../Button'
import Modal from '../'

const FoundPair = ({
  colorCode,
  onStartOver,
  onKeepPlaying,
  description,
  footnote,
  showRaffle,
}) => {
  return (
    <Modal
      header="You remembered!"
      colorCode={colorCode}
      Actions={() => (
        <>
          <Button
            className="secondary-btn-dark"
            text="Start Over"
            onPress={onStartOver}
            data-gtm-start-over-match-button
          />
          <Button
            className="primary-btn"
            text="Keep Playing"
            onPress={onKeepPlaying}
            data-gtm-keep-playing-button
          />
          <small className="legal">{footnote}</small>
        </>
      )}
    >
      <span
        className="description"
        dangerouslySetInnerHTML={{ __html: description }}
      ></span>
      {showRaffle && (
        <small>
          Keep playing and match all the cards to have a chance to be entered
          into our raffle!
        </small>
      )}
    </Modal>
  )
}

export default FoundPair
