import React, { useState, useEffect, useCallback, useRef } from 'react'
import Moment from 'moment'

import {
  prepareDuplicatedList,
  flipAllCards,
  shuffleCards,
  getElementsPerRowByContainer,
  getCarSizesByContainerWidth,
} from '../../utils/game-helper'
import { API_URL } from '../../config'

import Button from '../../components/Button'
import Card from '../../components/Card'
import ScoreModal from '../../components/Modal/ScoreModal/ScoreModal'
import InstantWinner from '../../components/Modal/InstantWinner/InstantWinner'
import FoundPairModal from '../../components/Modal/FoundPair'
import SuccessModal from '../../components/Modal/SuccessModal'
import SoundEffect from '../../components/SoundEffect'

import './styles.scss'
import Loader from '../../images/loader.png'
import Logo from '../../logo.svg'

import shuffleOgg from '../../audio/shuffle.ogg'
import shuffleMp3 from '../../audio/shuffle.mp3'
import fadeOgg from '../../audio/fade.ogg'
import fadeMp3 from '../../audio/fade.mp3'
import openOgg from '../../audio/open.ogg'
import openMp3 from '../../audio/open.mp3'
import closeOgg from '../../audio/close.ogg'
import closeMp3 from '../../audio/close.mp3'
import matchOgg from '../../audio/match.ogg'
import matchMp3 from '../../audio/match.mp3'
import badMatchOgg from '../../audio/bad_match.ogg'
import badMatchMp3 from '../../audio/bad_match.mp3'
import congratsOgg from '../../audio/congrats.ogg'
import congratsMp3 from '../../audio/congrats.mp3'
import flipOgg from '../../audio/flip.ogg'
import flipMp3 from '../../audio/flip.mp3'

const MAX_SHUFFLE_TIMES = 3

let prevBenefits
let shuffleRemains = MAX_SHUFFLE_TIMES
let closureSoundOn
let gameStartTime

export default ({ showRaffle, cards, initialGiftsLeft }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [soundOn, setSoundOn] = useState(true)
  const [isSelectAllowed, setIsSelectAllowed] = useState(false)
  const [pairFound, setPairFound] = useState([])
  const [benefits, setBenefits] = useState([])
  const [currentFoundPair, setCurrentFoundPair] = useState(null)
  const [isScoreVisible, setIsScoreVisible] = useState(false)
  const [giftsLeft, setGiftsLeft] = useState(0)
  const [giftInfo, setGiftInfo] = useState(null)
  const [isInstantWinnerVisible, setIsInstantWinnerVisible] = useState(false)
  const [isSuccessVisible, setIsSuccessVisible] = useState(false)

  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const shuffleSoundRef = useRef(null)
  const fadeSoundRef = useRef(null)
  const openSoundRef = useRef(null)
  const closeSoundRef = useRef(null)
  const matchSoundRef = useRef(null)
  const badMatchSoundRef = useRef(null)
  const congratsSoundRef = useRef(null)
  const flipSoundRef = useRef(null)

  const lastPlayedSound = useRef(null)
  const containerRef = useRef(null)

  // closure mechanism to have access to actual value inside the async calls
  closureSoundOn = soundOn

  const pullNewList = async () => {
    try {
      setBenefits(prepareDuplicatedList(cards))
      setGiftsLeft(initialGiftsLeft)

      if (Array.isArray(cards) && cards.length > 0 && closureSoundOn) {
        fadeSoundRef.current.play()
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onPlay = (soundRef) => {
    // set last played sound
    lastPlayedSound.current = soundRef.current
  }

  const shuffle = useCallback(() => {
    if (shuffleRemains > 0) {
      setTimeout(() => {
        setBenefits((currentBenefits) => shuffleCards(currentBenefits))

        if (shuffleRemains === MAX_SHUFFLE_TIMES && closureSoundOn) {
          shuffleSoundRef.current.play()
        }

        shuffleRemains--
        shuffle()
      }, 700)
    } else {
      // allow to click on items when all shuffles have been finished
      setIsSelectAllowed(true)
    }
  }, [])

  const secondFlip = useCallback(() => {
    // flip back
    setTimeout(() => {
      setBenefits((currentBenefits) => flipAllCards(currentBenefits))

      if (closureSoundOn) {
        closeSoundRef.current.play()
      }

      shuffle()
    }, 3000)
  }, [shuffle])

  const firstFlip = useCallback(() => {
    setTimeout(() => {
      setBenefits((currentBenefits) => flipAllCards(currentBenefits))

      if (closureSoundOn) {
        openSoundRef.current.play()
      }

      secondFlip()
    }, 1000)
  }, [secondFlip])

  useEffect(() => {
    pullNewList()
    window.addEventListener('resize', forceUpdate)
    gameStartTime = Moment.utc()

    window.onbeforeunload = () => {
      const duration = Moment.utc(Moment.utc().diff(gameStartTime))

      if (window.gtag) {
        window.gtag('event', 'GAME_DURATION', {
          event_category: 'User',
          event_label: duration.format('HH:mm:ss'),
          value: +duration.format('s'),
        })
      }
    }

    return () => {
      window.removeEventListener('resize', forceUpdate)

      const duration = Moment.utc(Moment.utc().diff(gameStartTime))

      if (window.gtag) {
        window.gtag('event', 'GAME_DURATION', {
          event_category: 'User',
          event_label: duration.format('HH:mm:ss'),
          value: +duration.format('s'),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // detect first data retrieval
    if (
      (!Array.isArray(prevBenefits) || prevBenefits.length === 0) &&
      benefits.length > 0
    ) {
      firstFlip()
    } else {
      const flipped = benefits.filter(({ isFlipped }) => isFlipped)

      // flip back if flipped count is more than paired x 2
      if (flipped.length >= pairFound.length * 2 + 2 && isSelectAllowed) {
        // wait animation ends
        setTimeout(() => {
          setBenefits((prevBenefits) =>
            prevBenefits.map((item) => ({
              ...item,
              isFlipped:
                // flip back only those that does not have pairs
                !pairFound.some((pairTitle) => pairTitle === item.title) &&
                flipped.some(({ id }) => id === item.id)
                  ? !item.isFlipped
                  : !!item.isFlipped,
            }))
          )
          setIsSelectAllowed(true)

          if (closureSoundOn) {
            badMatchSoundRef.current.play()
          }
        }, 1000)

        setIsSelectAllowed(false)
      }
    }

    prevBenefits = benefits
  }, [benefits, firstFlip, isSelectAllowed, pairFound])

  const onToggleSound = () => {
    // stop sound if is currently playing
    if (soundOn && lastPlayedSound.current) {
      lastPlayedSound.current.pause()
      lastPlayedSound.current.currentTime = 0
    }
    setSoundOn(!soundOn)
  }

  const onStartOver = () => window.location.reload()

  // reset found pair to close the modal
  const onKeepPlaying = () => setCurrentFoundPair(null)

  const checkInstantWinner = async (foundBenefit) => {
    if (giftsLeft > 0 && !giftInfo) {
      try {
        // if there are gifts left - check for instant winner
        const response = await fetch(`${API_URL}/check-winner`, {
          method: 'POST',
        })
        const responseJSON = await response.json()

        if (responseJSON.giftsLeft || responseJSON.giftsLeft === 0) {
          setGiftsLeft(responseJSON.giftsLeft)
        }

        if (responseJSON.gift) {
          setGiftInfo(responseJSON.gift)

          setIsInstantWinnerVisible(true)

          if (window.gtag) {
            window.gtag('event', 'INSTANT_WINNER', {
              event_category: 'User',
            })
          }

          if (closureSoundOn) {
            congratsSoundRef.current.play()
          }
        } else {
          setCurrentFoundPair(foundBenefit)

          if (closureSoundOn) {
            matchSoundRef.current.play()
          }
        }
      } catch (ex) {
        console.warn(ex)

        setCurrentFoundPair(foundBenefit)

        if (closureSoundOn) {
          matchSoundRef.current.play()
        }
      }
    } else {
      setCurrentFoundPair(foundBenefit)

      if (closureSoundOn) {
        matchSoundRef.current.play()
      }
    }
  }

  const onCardClick = (benefitId) => {
    const foundBenefit = benefits.find(({ id }) => id === benefitId)

    if (foundBenefit) {
      // disable manually inflipping existed
      if (foundBenefit.isFlipped) {
        return
      }

      if (closureSoundOn) {
        flipSoundRef.current.play()
      }

      const foundPair = benefits.find(
        ({ title, id }) =>
          title === foundBenefit.title && id !== foundBenefit.id
      )

      // when found pair - show modal and increment found counter
      if (foundPair?.isFlipped) {
        setPairFound((pairs) => pairs.concat([foundPair.title]))

        const flippedCount = benefits.filter(
          ({ isFlipped }) => isFlipped
        ).length
        // let animation to finish
        setTimeout(async () => {
          // is last pair
          if (flippedCount >= benefits.length - 1) {
            // show score
            setIsScoreVisible(true)

            if (window.gtag) {
              window.gtag('event', 'GAME_FINISHED', {
                event_category: 'User',
              })
            }

            if (closureSoundOn) {
              congratsSoundRef.current.play()
            }
          } else {
            await checkInstantWinner(foundBenefit)
          }
        }, 1000)
      }

      setBenefits((prevBenefits) =>
        prevBenefits.map((item) => ({
          ...item,
          isFlipped: item.id === benefitId ? !item.isFlipped : !!item.isFlipped,
        }))
      )
    }
  }

  const onRaffleSubmit = useCallback(async (raffleForm) => {
    try {
      if (raffleForm) {
        await fetch(`${API_URL}/submit-raffle`, {
          method: 'POST',
          body: JSON.stringify(raffleForm),
        })

        setIsScoreVisible(false)
        setIsSuccessVisible(true)
      }
    } catch (ex) {}
  }, [])

  const onEmailSubmit = useCallback(async (emailForm) => {
    try {
      if (emailForm) {
        await fetch(`${API_URL}/send-email`, {
          method: 'POST',
          body: JSON.stringify(emailForm),
        })

        setIsInstantWinnerVisible(false)
        setIsSuccessVisible(true)
      }
    } catch (ex) {}
  }, [])

  const containerWidth =
    (containerRef.current && containerRef.current.clientWidth) || 200
  const maxElementsPerRow = getElementsPerRowByContainer(containerWidth)
  const { height, marginBottom } = getCarSizesByContainerWidth(
    containerWidth,
    maxElementsPerRow
  )
  const cardContainerHeight =
    Math.ceil(benefits.length / maxElementsPerRow) * (height + marginBottom) // row height + margin

  return (
    <>
      <div
        ref={containerRef}
        className={`game-container ${
          isLoading || benefits.length === 0 ? 'full-height' : ''
        }`}
      >
        {isLoading ? (
          <img src={Loader} className="loader" alt="Loading..." />
        ) : benefits.length > 0 ? (
          <>
            <div
              className="card-container"
              style={{ height: cardContainerHeight }}
            >
              {benefits.map(
                ({ id, poster, title, isFlipped, colorCode }, index) => (
                  <Card
                    key={id}
                    logo={Logo}
                    imageSrc={poster}
                    title={title}
                    onClick={() => onCardClick(id)}
                    index={index}
                    colorCode={colorCode}
                    isFlipped={isFlipped}
                    disabled={!isSelectAllowed}
                    containerWidth={containerWidth}
                  />
                )
              )}
            </div>
            <div className="controls">
              <Button
                className="secondary-btn-dark"
                text="Start Over"
                onPress={onStartOver}
                data-gtm-start-over-button
              />
              <Button
                className="secondary-btn-light"
                text={`Sound ${soundOn ? 'Off' : 'On'}`}
                onPress={onToggleSound}
                data-gtm-sound-button
              />
            </div>
          </>
        ) : (
          <span className="error">Empty Response</span>
        )}
      </div>
      {currentFoundPair && (
        <FoundPairModal
          colorCode={currentFoundPair.colorCode}
          onStartOver={onStartOver}
          onKeepPlaying={onKeepPlaying}
          description={currentFoundPair.description}
          footnote={currentFoundPair.footnote}
          showRaffle={showRaffle}
        />
      )}
      {isScoreVisible && (
        <ScoreModal onSubmit={onRaffleSubmit} showRaffle={showRaffle} />
      )}
      {isInstantWinnerVisible && giftInfo && giftInfo.activationCode ? (
        <InstantWinner
          activationCode={giftInfo.activationCode}
          onClose={() => setIsInstantWinnerVisible(false)}
          onSubmit={onEmailSubmit}
          claimNumber={giftInfo.claimNumber}
          dollarAmount={giftInfo.dollarAmount}
        />
      ) : null}
      {isSuccessVisible && (
        <SuccessModal onClose={() => setIsSuccessVisible(false)} />
      )}
      <SoundEffect
        ref={shuffleSoundRef}
        oggPath={shuffleOgg}
        mp3Path={shuffleMp3}
        onPlay={onPlay}
      />
      <SoundEffect
        ref={openSoundRef}
        oggPath={openOgg}
        mp3Path={openMp3}
        onPlay={onPlay}
      />
      <SoundEffect
        ref={closeSoundRef}
        oggPath={closeOgg}
        mp3Path={closeMp3}
        onPlay={onPlay}
      />
      <SoundEffect
        ref={matchSoundRef}
        oggPath={matchOgg}
        mp3Path={matchMp3}
        onPlay={onPlay}
      />
      <SoundEffect
        ref={badMatchSoundRef}
        oggPath={badMatchOgg}
        mp3Path={badMatchMp3}
        onPlay={onPlay}
      />
      <SoundEffect
        ref={fadeSoundRef}
        oggPath={fadeOgg}
        mp3Path={fadeMp3}
        onPlay={onPlay}
      />
      <SoundEffect
        ref={congratsSoundRef}
        oggPath={congratsOgg}
        mp3Path={congratsMp3}
        onPlay={onPlay}
      />
      <SoundEffect
        ref={flipSoundRef}
        oggPath={flipOgg}
        mp3Path={flipMp3}
        onPlay={onPlay}
      />
    </>
  )
}
