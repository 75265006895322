import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.png'
import './styles.scss'

const Home = ({ showRaffle }) => {
  return (
    <Link to="/match-game" className="home-container" data-gtm-begin-game>
      <div className="logo">
        <img src={Logo} alt="logo" />
      </div>
      <h1>
        The
        <br />
        Memory&nbsp;Drill
      </h1>
      <p>
        Find the matching cards and see why ADA&nbsp;Members&nbsp;Insurance
        Plans are the smart match for your practice and your life.
        <br />
        {showRaffle && (
          <>
            Complete the game and enter our raffle to win
            one&nbsp;of&nbsp;six&nbsp;HomePod&nbsp;minis&#8482;.
            <br />
            You could also be an instant winner of an Amazon Gift Card!
          </>
        )}
      </p>
      <span className="link-text">Touch anywhere to begin</span>
    </Link>
  )
}

export default Home
