/**
 * Object that represents card item to render
 * @typedef {Object} CardItem
 * @property {(number|string)} id - Id of the item
 * @property {boolean?} isFlipped - Flag that shows flipped state of the card item
 */

/**
 * Object that represents the position in pixels for the X and Y axis
 * @typedef {Object} Position
 * @property {number} posX - Position in X axis
 * @property {number} posY - Position in Y axis
 */

/**
 * Element Size object
 * @typedef {Object} Size
 * @property {number} width - Element with in pixels
 * @property {number} height - Element height in pixels
 * @property {number} marginBottom - Element margin bottom in pixels
 */

/**
 * Creates a duplicates for every list item with the initial x and y positions
 *
 * @param {Array<CardItem>} [list=[]] - A list to make a copy from
 * @returns {Array<CardItem>} - A list with duplicated items with the initial position
 */
export const prepareDuplicatedList = (list = []) =>
  list.concat(
    list.map((item) => ({
      ...item,
      id: `${item.id}-dup`,
    }))
  )

/**
 * Toogles the filled state of all the card items
 *
 * @param {Array<CardItem>} list - A list of card items
 * @return {Array<CardItem>} - A list of updated card items
 */
export const flipAllCards = (list) =>
  list.map((item) => ({
    ...item,
    isFlipped: !item.isFlipped,
  }))

/**
 * Gets the X and Y position of the item depending on the index in array
 *
 * @param {number} index - Index of the item in the array
 * @param {number} [maxItemsPerRow=4] - Max items per row
 * @param {Size} itemSize - Card item sizes
 * @returns {Position} - Position of the item across the x/y axis
 */
export const getPositionsByIndex = (index, maxItemsPerRow = 4, itemSize) => ({
  posX:
    Math.floor(index / maxItemsPerRow) *
      (itemSize.height + itemSize.marginBottom) +
    itemSize.marginBottom / 2, // height per row + margin + half of margin
  posY:
    Math.floor(index % maxItemsPerRow) *
      (itemSize.width + itemSize.marginBottom) +
    itemSize.marginBottom / 2, // width per row + margin + half of margin
})

/**
 * Shuffles an array
 *
 * @param {Array<CardItem>} list - A list to shuffle
 * @returns {Array<CardItem>} - Shuffled array
 */
export const shuffleCards = (list) => {
  let currentIndex = list.length,
    temporaryItem,
    randomIndex
  const newList = [...list]

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    temporaryItem = newList[currentIndex]
    newList[currentIndex] = newList[randomIndex]
    newList[randomIndex] = temporaryItem
  }

  return newList
}

/**
 * Gets the number of elements per row by container width
 *
 * @param {number} containerWidth - Container width in pixels
 * @returns {number} - Number of elements per row
 */
export const getElementsPerRowByContainer = (containerWidth) =>
  window.outerWidth > 500 ? 4 : 3

/**
 * Calculates sizes of the card element depending on the containerWidth
 *
 * @param {number} containerWidth - Container width in pixels
 * @param {number} maxElementsPerRow - Maximum number of elements per row
 * @returns {Size} - Width and height of the card element
 */
export const getCarSizesByContainerWidth = (
  containerWidth,
  maxElementsPerRow
) => {
  if (window.outerHeight > 500) {
    const baseWidth = (containerWidth / maxElementsPerRow) * 0.9

    return {
      width: baseWidth,
      height: baseWidth * 1.4,
      marginBottom: (containerWidth / maxElementsPerRow) * 0.1,
    }
  } else {
    const baseHeight = (window.outerHeight / 3) * 0.975

    return {
      width: baseHeight / 1.4,
      height: baseHeight,
      marginBottom: (window.outerHeight / 3) * 0.025,
    }
  }
}
