export default [
  'Alabama',
  'Alberta',
  'Asdoh-Arizona',
  'Baylor',
  'Boston',
  'British Columbia',
  'Buffalo',
  'California-Los Angeles',
  'California-San Francisco',
  'Case Western',
  'Colorado',
  'Columbia',
  'Connecticut',
  'Creighton',
  'Dalhousie',
  'Detroit Mercy',
  'East Carolina University',
  'Florida',
  'Georgia',
  'Harvard',
  'Howard',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kentucky',
  'Lake Erie',
  'Laval',
  'Loma Linda',
  'Louisiana State',
  'Louisville',
  'Manitoba',
  'Marquette',
  'Maryland',
  'McGill',
  'Meharry',
  'Michigan',
  'Midwestern-Arizona',
  'Midwestern-Illinois',
  'Minnesota',
  'Mississippi',
  'Missouri-Kansas City',
  'Missouri-Kirksville',
  'Montreal',
  'Nebraska',
  'Nevada-Las Vegas',
  'New England',
  'New Jersey',
  'New York',
  'North Carolina',
  'Nova Southeastern',
  'Ohio State',
  'Oklahoma',
  'Ontario',
  'Oregon',
  'Pacific',
  'Pennsylvania',
  'Pittsburgh',
  'Puerto Rico',
  'Roseman',
  'Rutgers',
  'Saskatchewan',
  'School is Unknown',
  'South Carolina',
  'Southern California',
  'Southern Illinois',
  'Stony Brook',
  'Temple',
  'Tennessee',
  'Texas A&M',
  'Texas-Houston',
  'Texas-San Antonio',
  'Toronto',
  'Touro',
  'Tufts',
  'Utah',
  'Virginia',
  'Washington',
  'West Virginia',
  'Western',
]
