import React, { useState, useCallback } from 'react'
import RaffleForm from './components/RaffleForm'
import GreetingMessage from './components/GreetingMessage'
import ExitMessage from './components/ExitMessage'

import Logo from '../../../images/logo.png'
import '../styles.scss'
import './styles.scss'

const ScoreModal = ({ onSubmit, showRaffle }) => {
  const [isFormVisible, setIsFormVisible] = useState(false)

  const onEnterToWinClick = useCallback(() => setIsFormVisible(true), [])

  return (
    <div className="overlay">
      <div className="modal-wrapper">
        <div className="modal modal-score">
          <span
            className="modal-bg"
            style={{ backgroundColor: 'white' }}
          ></span>
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>

          {isFormVisible ? (
            <RaffleForm onSubmit={onSubmit} />
          ) : showRaffle ? (
            <GreetingMessage
              onEnterToWinClick={onEnterToWinClick}
              showRaffle={showRaffle}
            />
          ) : (
                <ExitMessage />
              )}
        </div>
      </div>
    </div>
  )
}

export default ScoreModal
