import React from 'react'

import Button from '../../Button'
import Modal from '../'

export default ({ onClose }) => (
  <Modal
    header="Success"
    children="Your form has been submitted successfully!"
    Actions={() => (
      <Button className="secondary-btn-dark" text="Close" onPress={onClose} />
    )}
    colorCode="#339933"
  />
)
