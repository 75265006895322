import React, { useState, useEffect } from 'react'
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom'

import { API_URL } from './config'
import Loader from './images/loader.png'
import HomePage from './pages/Home'
import GamePage from './pages/Game'

export default () => {
  const [showRaffle, setShowRaffle] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [cards, setCards] = useState(null)
  const [initialGiftsLeft, setInitialGiftsLeft] = useState(0)

  useEffect(() => {
    let mounted = true

    setIsLoading(true)
    ;(async () => {
      try {
        const response = await fetch(`${API_URL}/match-game`)
        const responseJSON = await response.json()
        if (mounted) {
          setShowRaffle(responseJSON.enableRaffle)

          setCards(responseJSON.cards || [])
          setInitialGiftsLeft(responseJSON.giftsLeft)
        }
      } finally {
        if (mounted) setIsLoading(false)
      }
    })()

    return () => {
      mounted = false
    }
  }, [])
  return isLoading ? (
    <div className={`game-container full-height`}>
      <img src={Loader} className="loader" alt="Loading..." />
    </div>
  ) : (
    <HashRouter>
      <Switch>
        <Route
          path="/"
          exact
          component={(props) => <HomePage {...props} showRaffle={showRaffle} />}
        />
        <Route
          path="/match-game"
          component={(props) => (
            <GamePage
              {...props}
              showRaffle={showRaffle}
              cards={cards}
              initialGiftsLeft={initialGiftsLeft}
            />
          )}
        />
        <Redirect to="/" />
      </Switch>
    </HashRouter>
  )
}
