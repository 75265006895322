import React, { useState } from 'react'

import Logo from '../../../images/logo-white.png'
import '../styles.scss'
import './styles.scss'

import Input from '../../Input'
import Button from '../../Button'

const initialState = {
  email: '',
  errors: [],
}

/* eslint-disable no-useless-escape */
const emailReg =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
/* eslint-enable no-useless-escape */

export default ({
  activationCode,
  onClose,
  onSubmit,
  dollarAmount,
  claimNumber,
}) => {
  const [formState, setFormState] = useState(initialState)

  const { email, errors } = formState

  const onSubmitForm = () => {
    const newErrors = []

    if (email && !emailReg.test(email.toLowerCase())) {
      newErrors.push({
        field: 'email',
        message: 'Email has invalid format',
      })
    }

    if (newErrors.length > 0) {
      setFormState((prevState) => ({
        ...prevState,
        errors: newErrors,
      }))
      return
    }

    // if (window.gtag) {
    //   window.gtag('event', 'RAFFLE_FORM_SUBMITTED', {
    //     event_category: 'User',
    //   })
    // }
    onSubmit({
      ...formState,
      activationCode,
      dollarAmount,
      claimNumber,
    })
  }

  const onInputChange = (event) => {
    const { value, name } = event.target

    setFormState((currentState) => ({
      ...currentState,
      [name]: value,
      errors: currentState.errors.filter(
        ({ field }) =>
          field !== name ||
          (name === 'email' && !emailReg.test(value)) ||
          (!value && name !== 'email')
      ),
    }))
  }

  return (
    <div className="overlay">
      <div className="modal-wrapper" data-gtm-instant-winner>
        <div className="modal instant-winner-modal">
          <span
            className="modal-bg"
            style={{ backgroundColor: '#339933' }}
          ></span>
          <div className="close-modal-button" onClick={onClose}>
            X
          </div>
          <div className="modal-header">
            <div>
              <span>Yay!</span>
              <br /> You're an instant winner!
            </div>
          </div>
          <div className="modal-body">
            <form data-gtm-raffle-form>
              <p className="smaller">
                Just enter your email address here and we’ll send you your $
                {dollarAmount} Amazon Gift card claim code. Congrats and thank
                you.
              </p>
              <div className="email-wrapper">
                <small className="required">Required field</small>
                <Input
                  required
                  label="Email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={onInputChange}
                  errors={errors}
                />
                <div className="controls">
                  <Button
                    className="secondary-btn-dark"
                    text="Send"
                    onPress={onSubmitForm}
                  />
                </div>
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>
            <small class="legal">
              By providing your email, you are consenting to receive
              non-sweepstakes-related communications from the administrator of
              the ADA Members Insurance Plans.
              <br />
              <br />
              ADA® is a registered trademark of the American Dental Association
              and Protective® is a registered trademark of Protective Life
              Insurance Company and Protective Life and Annuity Insurance
              Company.
            </small>
          </div>
        </div>
      </div>
    </div>
  )
}
