import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import cn from 'classnames'

import DentalSchools from '../../../../constants/dentalSchools'
import Button from '../../../Button'
import Input from '../../../Input'

import '../../styles.scss'
import './../styles.scss'

const initialState = {
  firstName: '',
  lastName: '',
  adaNumber: '',
  phone: '',
  email: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  graduationYear: '',
  school: '',
  month: '',
  day: '',
  year: '',
  errors: [],
}

// eslint-disable-next-line no-useless-escape
const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const years = []

for (let i = 2025; i > 1920; i--) {
  years.push(i)
}

export default ({ onSubmit }) => {
  const [formState, setFormState] = useState(initialState)

  const {
    firstName,
    lastName,
    adaNumber,
    phone,
    email,
    address,
    city,
    state,
    zip,
    graduationYear,
    school,
    errors,
    day,
    month,
    year,
  } = formState

  const onSubmitForm = () => {
    const newErrors = []
    let dateOfBirth

    if (!firstName) {
      newErrors.push({
        field: 'firstName',
        message: 'First Name is required',
      })
    }
    if (!lastName) {
      newErrors.push({
        field: 'lastName',
        message: 'Last Name is required',
      })
    }
    if (!phone || phone.length !== 11) {
      newErrors.push({
        field: 'phone',
        message: 'Phone number format is invalid',
      })
    }
    if (email && !emailReg.test(email.toLowerCase())) {
      newErrors.push({
        field: 'email',
        message: 'Email has invalid format',
      })
    }
    if (!address) {
      newErrors.push({
        field: 'address',
        message: 'Address is required',
      })
    }
    if (!city) {
      newErrors.push({
        field: 'city',
        message: 'City is required',
      })
    }
    if (!zip) {
      newErrors.push({
        field: 'zip',
        message: 'Zip code is required',
      })
    }
    if (!graduationYear) {
      newErrors.push({
        field: 'graduationYear',
        message: 'Graduation year is required',
      })
    }
    if (!state) {
      newErrors.push({
        field: 'state',
        message: 'State is required',
      })
    }
    if (!school) {
      newErrors.push({
        field: 'school',
        message: 'Dental school is required',
      })
    }
    if (
      !day ||
      !month ||
      !year ||
      day === 'Day' ||
      (month === 'Month') | (year === 'Year')
    ) {
      newErrors.push({
        field: 'day',
        message: 'Please select correct date',
      })
    } else {
      try {
        dateOfBirth = new Date(formState.year, formState.month, formState.day)
      } catch {
        newErrors.push({
          field: 'day',
          message: 'Please select correct date',
        })
      }
    }

    if (newErrors.length > 0) {
      setFormState((prevState) => ({
        ...prevState,
        errors: newErrors,
      }))
      return
    }
    if (window.gtag) {
      window.gtag('event', 'RAFFLE_FORM_SUBMITTED', {
        event_category: 'User',
      })
    }
    onSubmit({
      ...formState,
      dateOfBirth,
    })
  }

  const onClear = () => {
    setFormState(initialState)
  }

  const onInputChange = (event) => {
    const { value, name } = event.target

    setFormState((currentState) => ({
      ...currentState,
      [name]: value,
      errors: currentState.errors.filter(
        ({ field }) =>
          field !== name ||
          (name === 'email' && !emailReg.test(value)) ||
          (!value && name !== 'email')
      ),
    }))
  }

  const phoneErrorText = errors.find(({ field }) => field === 'phone')?.message
  const stateErrorText = errors.find(({ field }) => field === 'state')?.message
  const gradYearErrorText = errors.find(
    ({ field }) => field === 'graduationYear'
  )?.message
  const schoolErrorText = errors.find(({ field }) => field === 'school')
    ?.message
  const dateErrorText = errors.find(({ field }) => field === 'day')?.message

  return (
    <div className="modal-content raffle-application-wrapper">
      <h2>Enter our raffle to win one of six HomePod minis&#8482;!</h2>
      <form data-gtm-raffle-form>
        <div className="form-flex-wrapper">
          <small className="required">Required field</small>
          <Input
            required
            label="First name"
            type="text"
            name="firstName"
            value={firstName}
            onChange={onInputChange}
            errors={errors}
          />
          <Input
            required
            label="Last name"
            type="text"
            name="lastName"
            value={lastName}
            onChange={onInputChange}
            errors={errors}
          />
          <Input
            label={
              <>
                Email <sup>†</sup>
              </>
            }
            type="email"
            name="email"
            value={email}
            onChange={onInputChange}
            errors={errors}
          />
          <div>
            <label className="required">Phone</label>
            <PhoneInput
              onlyCountries={['us']}
              countryCodeEditable={false}
              country={'us'}
              value={phone}
              inputClass={cn({
                error: !!phoneErrorText,
              })}
              onChange={(phoneToSet) =>
                setFormState((currentState) => ({
                  ...currentState,
                  phone: phoneToSet,
                  errors: currentState.errors.filter(
                    ({ field }) => field !== 'phone' || phoneToSet.length !== 11
                  ),
                }))
              }
            />
            {phoneErrorText && (
              <span className="error-message">{phoneErrorText}</span>
            )}
          </div>

          <Input
            required
            label="Street address"
            type="text"
            name="address"
            note="Please input address where&nbsp;you&nbsp;would&nbsp;like&nbsp;the prize to be shipped to"
            value={address}
            onChange={onInputChange}
            errors={errors}
          />
          <Input
            required
            label="City"
            type="text"
            name="city"
            value={city}
            onChange={onInputChange}
            errors={errors}
          />
          <p>
            <label className="required">State</label>
            <select
              required={true}
              name="state"
              value={state}
              className={cn({
                error: !!stateErrorText,
              })}
              onChange={onInputChange}
            >
              <option value=""></option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
            {stateErrorText && (
              <span className="error-message">{stateErrorText}</span>
            )}
          </p>
          <Input
            required
            label="Zip"
            type="number"
            name="zip"
            value={zip}
            onChange={onInputChange}
            errors={errors}
          />

          <p>
            <label className="required">Dental School Graduation Year</label>
            <select
              required={true}
              name="graduationYear"
              value={graduationYear}
              className={cn({
                error: !!gradYearErrorText,
              })}
              onChange={onInputChange}
            >
              <option value=""></option>
              {years.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
            {gradYearErrorText && (
              <span className="error-message">{gradYearErrorText}</span>
            )}
          </p>
          <p className="birth-date-form-field">
            <label className="required">
              Date of Birth
              <span className="tooltip-wrapper">
                <span className="icon">?</span>
                <span className="message">
                  Date of birth will help us verify ADA membership
                  if&nbsp;ADA&nbsp;number&nbsp;is&nbsp;unknown
                </span>
              </span>
            </label>
            <div>
              <select
                required={true}
                name="month"
                value={month}
                onChange={onInputChange}
                className={cn({
                  error: !!dateErrorText,
                })}
                placeholder="Month"
              >
                <option>Month</option>
                <option value="0">January</option>
                <option value="1">February</option>
                <option value="2">March</option>
                <option value="3">April</option>
                <option value="4">May</option>
                <option value="5">June</option>
                <option value="6">July</option>
                <option value="7">August</option>
                <option value="8">September</option>
                <option value="9">October</option>
                <option value="10">November</option>
                <option value="11">December</option>
              </select>
              <select
                required={true}
                name="day"
                value={day}
                onChange={onInputChange}
                className={cn({
                  error: !!dateErrorText,
                })}
                placeholder="Day"
              >
                <option>Day</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                <option value="31">31</option>
              </select>
              <select
                required={true}
                name="year"
                value={year}
                onChange={onInputChange}
                className={cn({
                  error: !!dateErrorText,
                })}
                placeholder="Year"
              >
                <option>Year</option>
                <option value="2020">2020</option>
                <option value="2019">2019</option>
                <option value="2018">2018</option>
                <option value="2017">2017</option>
                <option value="2016">2016</option>
                <option value="2015">2015</option>
                <option value="2014">2014</option>
                <option value="2013">2013</option>
                <option value="2012">2012</option>
                <option value="2011">2011</option>
                <option value="2010">2010</option>
                <option value="2009">2009</option>
                <option value="2008">2008</option>
                <option value="2007">2007</option>
                <option value="2006">2006</option>
                <option value="2005">2005</option>
                <option value="2004">2004</option>
                <option value="2003">2003</option>
                <option value="2002">2002</option>
                <option value="2001">2001</option>
                <option value="2000">2000</option>
                <option value="1999">1999</option>
                <option value="1998">1998</option>
                <option value="1997">1997</option>
                <option value="1996">1996</option>
                <option value="1995">1995</option>
                <option value="1994">1994</option>
                <option value="1993">1993</option>
                <option value="1992">1992</option>
                <option value="1991">1991</option>
                <option value="1990">1990</option>
                <option value="1989">1989</option>
                <option value="1988">1988</option>
                <option value="1987">1987</option>
                <option value="1986">1986</option>
                <option value="1985">1985</option>
                <option value="1984">1984</option>
                <option value="1983">1983</option>
                <option value="1982">1982</option>
                <option value="1981">1981</option>
                <option value="1980">1980</option>
                <option value="1979">1979</option>
                <option value="1978">1978</option>
                <option value="1977">1977</option>
                <option value="1976">1976</option>
                <option value="1975">1975</option>
                <option value="1974">1974</option>
                <option value="1973">1973</option>
                <option value="1972">1972</option>
                <option value="1971">1971</option>
                <option value="1970">1970</option>
                <option value="1969">1969</option>
                <option value="1968">1968</option>
                <option value="1967">1967</option>
                <option value="1966">1966</option>
                <option value="1965">1965</option>
                <option value="1964">1964</option>
                <option value="1963">1963</option>
                <option value="1962">1962</option>
                <option value="1961">1961</option>
                <option value="1960">1960</option>
                <option value="1959">1959</option>
                <option value="1958">1958</option>
                <option value="1957">1957</option>
                <option value="1956">1956</option>
                <option value="1955">1955</option>
                <option value="1954">1954</option>
                <option value="1953">1953</option>
                <option value="1952">1952</option>
                <option value="1951">1951</option>
                <option value="1950">1950</option>
                <option value="1949">1949</option>
                <option value="1948">1948</option>
                <option value="1947">1947</option>
                <option value="1946">1946</option>
                <option value="1945">1945</option>
                <option value="1944">1944</option>
                <option value="1943">1943</option>
                <option value="1942">1942</option>
                <option value="1941">1941</option>
                <option value="1940">1940</option>
                <option value="1939">1939</option>
                <option value="1938">1938</option>
                <option value="1937">1937</option>
                <option value="1936">1936</option>
                <option value="1935">1935</option>
                <option value="1934">1934</option>
                <option value="1933">1933</option>
                <option value="1932">1932</option>
                <option value="1931">1931</option>
                <option value="1930">1930</option>
              </select>
            </div>
            {dateErrorText && (
              <span className="error-message">{dateErrorText}</span>
            )}
          </p>
          <p>
            <label className="required">Dental School</label>
            <select
              required={true}
              name="school"
              value={school}
              className={cn({
                error: !!schoolErrorText,
              })}
              onChange={onInputChange}
            >
              <option value=""></option>
              {DentalSchools.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
            {schoolErrorText && (
              <span className="error-message">{schoolErrorText}</span>
            )}
          </p>
          <Input
            label="ADA number"
            type="number"
            name="adaNumber"
            value={adaNumber}
            onChange={onInputChange}
            errors={errors}
          />
        </div>
        <div className="controls">
          <Button
            className="primary-btn"
            text="Submit"
            onPress={onSubmitForm}
          />
          <Button className="default-btn" text="Clear" onPress={onClear} />
        </div>
      </form>
      <small>
        † Email address is optional and not required to enter drawing. By
        providing your email, you are consenting to receive
        non-sweepstakes-related communications from the administrator of ADA
        Members Insurance Plans.
        <br />
        <br />
        Contest Rules: All ADA members are eligible to enter. No purchase is
        necessary. Winners will be randomly selected from all entries collected
        on-site during SmileCon 2021. A winner will be notified by email no
        later than November 30, 2021. Odds of winning depend on the number of
        entries. One entry per ADA member, please. Protective, and ADA staff and
        their families are ineligible to participate. Void where prohibited. To
        request a copy of the complete Official Rules, send a self-addressed
        stamped envelope to: Sweepstakes Rules, ADA Members Insurance Plans,
        2801 Highway 280 S Birmingham, AL, 35223. Requests must be received by
        November 30, 2021.
        <br />
        <br />
        This material is not a contract. Benefits are provided under a
        Protective Life Insurance Company (PLICO) group policy (TL-P 1-21, CI-P
        7-20, IP-P 7-20, HI-P 11-20, LT-P 1-21, OE-P 7-20, TP-P 11-20) filed in
        the State of Illinois; in New York through a Protective Life and Annuity
        Insurance Company (PLAIC) under group policy (TL-P-NY 1-21, CI-P-NY
        1-21, IP-P 10-NY 1-21, HI-P-NY 1-21, TL-P-NY 1-21, OE-P-NY 1-21, TP-P-NY
        10-20) issued to the American Dental Association by Protective Life. The
        ADA is entitled to receive royalties from the group policies issued to
        the ADA by Protective. Coverage is available to eligible ADA members in
        all fifty states and US territories under the aforementioned group
        policies. Each Plan participant will receive a Certificate of Insurance
        explaining the terms and conditions of the policy. “Protective” refers
        to Protective Life Insurance Company (home office in Nashville, TN and
        licensed in 49 states, excluding New York) and Protective Life and
        Annuity Insurance Company (home office in Birmingham, AL and licensed in
        New York). Certificates issued to ADA members residing outside of New
        York are issued by Protective Life Insurance Company. Certificates
        issued to ADA members residing in New York are issued by Protective Life
        and Annuity Insurance Company. ©2021 Protective Life Insurance Company.
        All Rights Reserved.
        <br />
        <br />
        ADA® is a registered trademark of the American Dental Association and
        Protective® is a registered trademark of Protective Life Insurance
        Company and Protective Life and Annuity Insurance Company.
      </small>
    </div>
  )
}
