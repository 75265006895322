import React from 'react'
import cn from 'classnames'

export default ({
  label,
  type,
  name,
  value,
  onChange,
  errors,
  note,
  required,
}) => {
  const errorText = errors.find(({ field }) => field === name)?.message

  const tooltip = (
    <span className="tooltip-wrapper">
      <span className="icon">?</span>
      <span className="message">{note}</span>
    </span>
  )

  return (
    <p>
      <label className={cn({ required })}>
        {label}
        {note ? tooltip : null}
      </label>
      <input
        required={required}
        type={type}
        name={name}
        value={value}
        className={cn({
          error: !!errorText,
        })}
        onChange={onChange}
      />
      {errorText && <span className="error-message">{errorText}</span>}
    </p>
  )
}
