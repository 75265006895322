import React from 'react'
import Button from '../../../Button'

export default React.memo(({ onEnterToWinClick }) => {
  return (
    <div className="exit-message-wrapper">
      <div className="modal-content">
        <div>
          <h1>Way to go!</h1>
          <p>
            You made all the matches! And you discovered the many ways ADA
            Members Insurance Plans can help meet your unique insurance needs
            and protect your practice, your income and your family. In other
            words, we’re the perfect match for you!
          </p>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className="primary-btn"
          text="Play Again"
          onPress={() => {
            window.location.reload()
          }}
        />
        <Button
          className="tertiary-btn"
          text="Exit Game"
          onPress={() => {
            window.location.reload()
          }}
        />
      </div>
    </div>
  )
})
