import React from 'react'

import './styles.scss'

export default ({ header, children, Actions, colorCode = '#fff' }) => {
  return (
    <div className="overlay">
      <div className="modal-wrapper">
        <div className="modal">
          <span
            className="modal-bg"
            style={{ backgroundColor: colorCode }}
          ></span>
          <div className="modal-header">{header}</div>
          <div className="modal-body">{children}</div>
          {Actions && (
            <div className="modal-footer">
              <Actions />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
