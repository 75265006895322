import React, { useState, useEffect, useRef } from 'react'
import { useSpring, animated } from 'react-spring'

import {
  getPositionsByIndex,
  getElementsPerRowByContainer,
  getCarSizesByContainerWidth,
} from '../../utils/game-helper'

import './styles.scss'

export default React.memo(
  ({
    imageSrc,
    title,
    onClick,
    containerClass,
    isFlipped = false,
    index,
    colorCode,
    disabled,
    containerWidth,
  }) => {
    const maxElementsPerRow = getElementsPerRowByContainer(containerWidth)
    const itemSize = getCarSizesByContainerWidth(
      containerWidth,
      maxElementsPerRow
    )
    const { posX, posY } = getPositionsByIndex(
      index,
      maxElementsPerRow,
      itemSize
    )
    const [isInitialized, setIsInitialized] = useState(false)
    const cardRef = useRef(null)

    useEffect(() => {
      // apply position animation only after first initializing
      setIsInitialized(true)
    }, [])

    const transitionState = useSpring({
      top: `${posX}px`,
      left: `${posY}px`,
      from: () => ({
        top: cardRef.current?.style?.top || '0px',
        left: cardRef.current?.style?.left || '0px',
      }),
    })

    const cardStyles = isInitialized
      ? transitionState
      : {
          top: `${posX}px`,
          left: `${posY}px`,
        }

    return (
      <animated.div
        ref={cardRef}
        className={`card ${containerClass || ''} ${isFlipped ? 'flipped' : ''}`}
        onClick={() => {
          if (!disabled) {
            onClick()
          }
        }}
        style={{
          ...cardStyles,
          height: `${itemSize.height}px`,
          width: `${itemSize.width}px`,
        }}
      >
        <div className="card-inner">
          <div className="back-side">
            <span>ADA Members Insurance Plans</span>
          </div>
          <div className="front-side">
            <div style={{ backgroundColor: colorCode }}>
              <img src={imageSrc} className="card-image" alt="card" />
              <span className="card-title">{title}</span>
            </div>
          </div>
        </div>
      </animated.div>
    )
  }
)
